@import url("https://fonts.googleapis.com/css2?family=Lustria&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo&family=Lustria&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Cerebri";
  src: url(./resources/fonts/cerebri.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Cerebri";
  src: url(./resources/fonts/cerebri-semi.ttf) format("truetype");
  font-weight: bolder;
}

@font-face {
  font-family: "Cerebri";
  src: url(./resources/fonts/cerebri-bold.ttf) format("truetype");
  font-weight: bold;
}

body {
  font-family: "Cerebri";
  background-color: #e0e0e0;
}

* {
  box-sizing: border-box;
}

@keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
