.Services {
  animation: fadeIn 1s;
  background-color: #ebebeb;
  color: black;
  padding-top: 160px;
  height: 100vh;

  &__Heading {
    font-family: "Lustria";
    font-size: 40px;
  }

  &__Container {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
    // border: 1px solid red;
    padding: 20px 0 50px 0;

    &__SingleService {
      margin: 30px 20px;
      //   border: 1px solid red;
      padding: 30px 60px;
      background-color: white;
      border-radius: 4px;
      transition: all 200ms;
      cursor: pointer;
      &:hover {
        transform: scale(1.01);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
          rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      }

      &--image {
        border: 1px solid rgb(139, 139, 139);
      }

      &--heading {
        margin-top: 5px;
        font-size: 19px;
      }

      &--subheading {
        color: rgb(160, 137, 6);
        margin-top: 2px;
        font-size: 14px;
      }
    }
  }
}

body {
  background-color: #ebebeb;
}
