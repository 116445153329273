.LiveStocks {
  height: 26px;
  background-color: black;
  color: white;
  padding: 5px 0;
  border-bottom: 1px solid white;

  //   display: flex;
  //   align-items: center;
  font-weight: 500;
  font-size: 10px;

  &__Stock {
    margin: 0 10px;
    display: flex !important;
    align-items: center;

    &--name {
      margin: 0 5px 0 0;
    }

    &--value {
      margin: 0 5px 0 0;
    }

    &--change {
      font-weight: bold;
      margin-bottom: 1px;
    }
  }
}
