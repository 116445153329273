@import url(https://fonts.googleapis.com/css2?family=Lustria&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arvo&family=Lustria&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Cerebri";
  src: url(/static/media/cerebri.52220a8e.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Cerebri";
  src: url(/static/media/cerebri-semi.dcda3674.ttf) format("truetype");
  font-weight: bolder;
}

@font-face {
  font-family: "Cerebri";
  src: url(/static/media/cerebri-bold.b28d46a0.ttf) format("truetype");
  font-weight: bold;
}

body {
  font-family: "Cerebri";
  background-color: #e0e0e0;
}

* {
  box-sizing: border-box;
}

@keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.LogoWelcome{background-color:#0a0a0a;border-radius:30px;color:white;width:650px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;padding:30px 30px;margin:0 auto;position:absolute;bottom:0;left:50%;transform:translate(-50%, 70%);display:flex;align-items:center;justify-content:space-between}.LogoWelcome__Logo{width:30%}.LogoWelcome__Logo--title{font-family:"Lustria";font-size:16px;margin-top:5px}.LogoWelcome__Logo--subtitle{margin-top:2px;font-weight:lighter;font-size:12px;color:#daa520}.LogoWelcome__Text{width:67%}.LogoWelcome__Text--heading{font-family:"Lustria";font-size:26px;line-height:130%}.LogoWelcome__Text--subtext{margin:10px auto 0 auto;width:90%;color:#dadada}

.LiveStocks{height:26px;background-color:black;color:white;padding:5px 0;border-bottom:1px solid white;font-weight:500;font-size:10px}.LiveStocks__Stock{margin:0 10px;display:flex !important;align-items:center}.LiveStocks__Stock--name{margin:0 5px 0 0}.LiveStocks__Stock--value{margin:0 5px 0 0}.LiveStocks__Stock--change{font-weight:bold;margin-bottom:1px}

.Navbar{position:fixed;width:100%;top:0;left:0;background-color:transparent;z-index:10;transition:all 300ms}.Navbar__Menu{color:white;padding:16px 22px;display:flex;justify-content:flex-start;align-items:center;font-family:"Lustria"}.Navbar__Menu--item{margin-left:50px;font-size:20px;cursor:pointer;transition:all;transition-duration:200ms}.Navbar__Menu--item:hover{transform:scale(1.1)}.Invert{background-color:#202020f8;color:white;border-bottom:2px solid #e5e5e5}

.Home{animation:fadeIn 1s}.Home__Top{background-color:#e0e0e0;position:relative;z-index:1}.Home__Graph{background-color:#e0e0e0;padding:200px 8% 80px 8%;display:flex;align-items:center}.Home__Greetings{background-color:#385662;color:white;padding:80px 0}.Home__Greetings__Head{font-family:"Lustria"}.Home__Greetings__Head--heading{font-size:32px;line-height:130%}.Home__Greetings__Video{background-color:whitesmoke;color:black;height:400px;width:700px;margin:50px auto;border:1px solid white;display:flex;align-items:center;justify-content:center}.Home__Greetings__Text{font-family:"Arvo", serif;width:80%;margin:50px auto;font-size:20px;line-height:130%;font-weight:100}.Home__Greetings__Explore{color:#ffc82f;font-family:"Lustria";font-weight:600;margin:20px auto;padding:3px 0;font-size:16px;cursor:pointer;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-bottom:2px solid rgba(255,255,255,0);transition:all 300ms}.Home__Greetings__Explore:hover{border-bottom:2px solid rgba(255,255,255,0.774)}

.Footer{background-color:#202020;padding:50px;max-width:100vw}.Footer__Container{color:white;display:flex;align-items:center;justify-content:center}.Footer__Container>div{margin:0 20px}.Footer__Container--heading{margin-top:5px;font-family:"Lustria";font-weight:500;font-size:20px}.Footer__Container--subheading{font-family:"Arvo", sans-serif;font-weight:500;font-size:12px}.Footer__Container--copyright{font-family:"Roboto", sans-serif}.Footer__Container--addr{font-family:"Roboto", sans-serif;margin:10px auto 0 auto;width:75%;font-size:14px}

.Services{animation:fadeIn 1s;background-color:#ebebeb;color:black;padding-top:160px;height:100vh}.Services__Heading{font-family:"Lustria";font-size:40px}.Services__Container{display:flex;align-items:center;justify-content:center;margin:0 auto;flex-wrap:wrap;padding:20px 0 50px 0}.Services__Container__SingleService{margin:30px 20px;padding:30px 60px;background-color:white;border-radius:4px;transition:all 200ms;cursor:pointer}.Services__Container__SingleService:hover{transform:scale(1.01);box-shadow:rgba(0,0,0,0.1) 0px 10px 15px -3px,rgba(0,0,0,0.05) 0px 4px 6px -2px}.Services__Container__SingleService--image{border:1px solid #8b8b8b}.Services__Container__SingleService--heading{margin-top:5px;font-size:19px}.Services__Container__SingleService--subheading{color:#a08906;margin-top:2px;font-size:14px}body{background-color:#ebebeb}

.Plans{padding-top:170px;background-color:#ebebeb}.Plans__Heading{font-family:"Lustria";font-size:40px}.Plans__Container{width:80%;margin:30px auto}.Plans__Container__Plan{display:flex;margin:80px 0}.Plans__Container__Plan--box{width:50%;padding:80px 40px;background-color:#202020;color:white}.Plans__Container__Plan--text{width:60%;display:flex;align-items:center;justify-content:center}.reverse{flex-direction:row-reverse}

.App {
  text-align: center;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

