.Navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 10;

  transition: all 300ms;

  &__Menu {
    // border: 1px solid white;
    color: white;
    padding: 16px 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-family: "Lustria";

    &--item {
      margin-left: 50px;
      font-size: 20px;
      cursor: pointer;
      transition: all;
      transition-duration: 200ms;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.Invert {
  background-color: #202020f8;
  color: white;
  border-bottom: 2px solid #e5e5e5;
}
