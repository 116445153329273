.Plans {
  padding-top: 170px;
  background-color: #ebebeb;

  &__Heading {
    font-family: "Lustria";
    font-size: 40px;
  }

  &__Container {
    width: 80%;
    margin: 30px auto;
    &__Plan {
      //   border: 1px solid gray;
      display: flex;
      margin: 80px 0;

      &--box {
        width: 50%;
        padding: 80px 40px;
        background-color: #202020;
        color: white;
      }
      &--text {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.reverse {
  flex-direction: row-reverse;
}
